export const SERVICE_LIST_REQUEST = "SERVICE_LIST_REQUEST";
export const SERVICE_LIST_SUCCESS = "SERVICE_LIST_SUCCESS";
export const SERVICE_LIST_FAIL = "SERVICE_LIST_FAIL";

export const SERVICE_ADD_REQUEST = "SERVICE_ADD_REQUEST";
export const SERVICE_ADD_SUCCESS = "SERVICE_ADD_SUCCESS";
export const SERVICE_ADD_FAIL = "SERVICE_ADD_FAIL";

export const SERVICE_DETAIL_REQUEST = "SERVICE_DETAIL_REQUEST";
export const SERVICE_DETAIL_SUCCESS = "SERVICE_DETAIL_SUCCESS";
export const SERVICE_DETAIL_FAIL = "SERVICE_DETAIL_FAIL";

export const SERVICE_UPDATE_REQUEST = "SERVICE_UPDATE_REQUEST";
export const SERVICE_UPDATE_SUCCESS = "SERVICE_UPDATE_SUCCESS";
export const SERVICE_UPDATE_FAIL = "SERVICE_UPDATE_FAIL";

export const SERVICE_DELETE_REQUEST = "SERVICE_DELETE_REQUEST";
export const SERVICE_DELETE_SUCCESS = "SERVICE_DELETE_SUCCESS";
export const SERVICE_DELETE_FAIL = "SERVICE_DELETE_FAIL";
