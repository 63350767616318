export const TYPE_LIST_REQUEST = "TYPE_LIST_REQUEST";
export const TYPE_LIST_SUCCESS = "TYPE_LIST_SUCCESS";
export const TYPE_LIST_FAIL = "TYPE_LIST_FAIL";

export const TYPE_ADD_REQUEST = "TYPE_ADD_REQUEST";
export const TYPE_ADD_SUCCESS = "TYPE_ADD_SUCCESS";
export const TYPE_ADD_FAIL = "TYPE_ADD_FAIL";

export const TYPE_DETAIL_REQUEST = "TYPE_DETAIL_REQUEST";
export const TYPE_DETAIL_SUCCESS = "TYPE_DETAIL_SUCCESS";
export const TYPE_DETAIL_FAIL = "TYPE_DETAIL_FAIL";

export const TYPE_UPDATE_REQUEST = "TYPE_UPDATE_REQUEST";
export const TYPE_UPDATE_SUCCESS = "TYPE_UPDATE_SUCCESS";
export const TYPE_UPDATE_FAIL = "TYPE_UPDATE_FAIL";

export const TYPE_DELETE_REQUEST = "TYPE_DELETE_REQUEST";
export const TYPE_DELETE_SUCCESS = "TYPE_DELETE_SUCCESS";
export const TYPE_DELETE_FAIL = "TYPE_DELETE_FAIL";
