import { toast } from "react-toastify";
import {
  TYPE_LIST_REQUEST,
  TYPE_LIST_SUCCESS,
  TYPE_LIST_FAIL,
  //
  TYPE_ADD_REQUEST,
  TYPE_ADD_SUCCESS,
  TYPE_ADD_FAIL,
  //
  TYPE_DETAIL_REQUEST,
  TYPE_DETAIL_SUCCESS,
  TYPE_DETAIL_FAIL,
  //
  TYPE_UPDATE_REQUEST,
  TYPE_UPDATE_SUCCESS,
  TYPE_UPDATE_FAIL,
  //
  TYPE_DELETE_REQUEST,
  TYPE_DELETE_SUCCESS,
  TYPE_DELETE_FAIL,
} from "../constants/typeConstants";

export const typeListReducer = (state = { types: [] }, action) => {
  switch (action.type) {
    case TYPE_LIST_REQUEST:
      return { loadingTypes: true, types: [] };
    case TYPE_LIST_SUCCESS:
      return {
        loadingTypes: false,
        types: action.payload.types,
      };
    case TYPE_LIST_FAIL:
      return { loadingTypes: false, errorTypes: action.payload };
    default:
      return state;
  }
};
