import { toast } from "react-toastify";
import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  //
  ORDER_ADD_REQUEST,
  ORDER_ADD_SUCCESS,
  ORDER_ADD_FAIL,
  //
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  //
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  //
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
} from "../constants/orderConstants";

export const createNewOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_ADD_REQUEST:
      return { loadingOrderAdd: true };
    case ORDER_ADD_SUCCESS:
      toast.success("Your order has been sent successfully");
      return {
        loadingOrderAdd: false,
        successOrderAdd: true,
      };
    case ORDER_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingOrderAdd: false,
        successOrderAdd: false,
        errorOrderAdd: action.payload,
      };
    default:
      return state;
  }
};
