import React from "react";
import FooterWeb from "../layouts/FooterWeb";
import HeaderWeb from "../layouts/HeaderWeb";

function AboutScreen() {
  return (
    <div className="w-full min-h-screen bg-white text-black">
      <HeaderWeb />
      <div className="py-15  "></div>
      <div className="container mx-auto py-10  px-2">
        <div className="text-2xl font-bold text-center">ABOUT US</div>
        <div className="flex md:flex-row flex-col items-center">
          <div className="md:w-1/3 w-full ">
            <img
              className="w-full"
              src="https://hijamaexpress.net/assets/hijama/hijamalogo.jpeg"
            />
          </div>
          <div className="md:w-2/3 w-full ">
            <div className="my-1">
              <b>Hijama</b> is a method of treatment where blood is drawn from
              small superficial skin incisions, through suction from the skin,
              and with it, releases toxins from within the body, reduces muscle
              inflammation, and kickstart the body's immune system.
            </div>
            <div className="my-1">
              <b>Hijama</b> cleanses & detoxifies the body, improves blood
              circulation, aids body pains, and refreshes both the mind and
              body! It can provide relief to many different conditions and acts
              as a preventative measure against many kinds of diseases.
            </div>
          </div>
        </div>
      </div>
      <FooterWeb />
    </div>
  );
}

export default AboutScreen;
