import { toast } from "react-toastify";
import {
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
  SERVICE_LIST_FAIL,
  //
  SERVICE_ADD_REQUEST,
  SERVICE_ADD_SUCCESS,
  SERVICE_ADD_FAIL,
  //
  SERVICE_DETAIL_REQUEST,
  SERVICE_DETAIL_SUCCESS,
  SERVICE_DETAIL_FAIL,
  //
  SERVICE_UPDATE_REQUEST,
  SERVICE_UPDATE_SUCCESS,
  SERVICE_UPDATE_FAIL,
  //
  SERVICE_DELETE_REQUEST,
  SERVICE_DELETE_SUCCESS,
  SERVICE_DELETE_FAIL,
} from "../constants/serviceSconstants";

export const serviceListReducer = (state = { services: [] }, action) => {
  switch (action.type) {
    case SERVICE_LIST_REQUEST:
      return { loadingServices: true, services: [] };
    case SERVICE_LIST_SUCCESS:
      return {
        loadingServices: false,
        services: action.payload.services,
      };
    case SERVICE_LIST_FAIL:
      return { loadingServices: false, errorServices: action.payload };
    default:
      return state;
  }
};
