import React, { useEffect, useState } from "react";
import FooterWeb from "../layouts/FooterWeb";
import HeaderWeb from "../layouts/HeaderWeb";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { addNewReview, gerReviewList } from "../redux/actions/reviewActions";
import { toast, ToastContainer } from "react-toastify";
import { TYPESELECT } from "../constants";
import "react-toastify/dist/ReactToastify.css";
import { addNewOrder } from "../redux/actions/orderActions";
import { getTypeList } from "../redux/actions/typeActions";
import Loader from "../components/Loader";
import Alert from "../components/Alert";

function BookingScreen() {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [gender, setGender] = useState("Male");
  const [genderError, setGenderError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const [date, setDate] = useState("");
  const [dateError, setDateError] = useState("");

  const [bookingSelect, setBookingSelect] = useState("");
  const [bookingSelectError, setBookingSelectError] = useState("");

  const [bookingRaison, setBookingRaison] = useState("");
  const [bookingRaisonError, setBookingRaisonError] = useState("");

  const [reviewName, setReviewName] = useState("");
  const [reviewNameError, setReviewNameError] = useState("");

  const [reviewContent, setReviewContent] = useState("");
  const [reviewContentError, setReviewContentError] = useState("");

  const listReviews = useSelector((state) => state.reviewList);
  const { reviews, loadingReviews, errorReviews } = listReviews;

  const reviewAdd = useSelector((state) => state.createNewReview);
  const { loadingReviewAdd, errorReviewAdd, successReviewAdd } = reviewAdd;

  const orderAdd = useSelector((state) => state.createNewOrder);
  const { loadingOrderAdd, errorOrderAdd, successOrderAdd } = orderAdd;

  const listTypes = useSelector((state) => state.typeList);
  const { types, loadingTypes, errorTypes } = listTypes;

  useEffect(() => {
    dispatch(getTypeList());
    dispatch(gerReviewList());
  }, [dispatch]);

  useEffect(() => {
    if (successReviewAdd) {
      dispatch(gerReviewList());
      setReviewContent("");
      setReviewContentError("");
      setReviewName("");
      setReviewNameError("");
    }
  }, [successReviewAdd]);

  useEffect(() => {
    if (successOrderAdd) {
      setFirstName("");
      setLastName("");
      setGender("Male");
      setEmail("");
      setPhone("");
      setAddress("");
      setDate("");
      setBookingSelect("");
      setBookingRaison("");
    }
  }, [successOrderAdd]);

  return (
    <div className="w-full min-h-screen bg-white text-black">
      <ToastContainer />
      <HeaderWeb />
      <div className="py-15  "></div>
      <div className="container mx-auto py-10">
        <div className="text-2xl font-bold text-center">Booking Now</div>
        <div className="my-5 text-center font-bold">
          Same day appointments no problem.
        </div>
        <div className="md:w-1/2 mx-auto px-2 w-full my-10">
          <div className="mt-2">First Name *</div>
          <div className="">
            <input
              value={firstName}
              onChange={(v) => setFirstName(v.target.value)}
              className="w-full border rounded border-[#aeb7c0] px-2 py-1 outline-none"
              type="text"
              placeholder="Your First Name"
            />
            {firstNameError && (
              <p className="text-[9px] italic text-danger leading-none mt-1">
                {firstNameError}
              </p>
            )}
          </div>
          <div className="mt-2">Last Name</div>
          <div className="">
            <input
              value={lastName}
              onChange={(v) => setLastName(v.target.value)}
              className="w-full border rounded border-[#aeb7c0] px-2 py-1 outline-none"
              type="text"
              placeholder="Your Last Name"
            />
            {lastNameError && (
              <p className="text-[9px] italic text-danger leading-none mt-1">
                {lastNameError}
              </p>
            )}
          </div>
          <div className="mt-2">Gender *</div>
          <div className="">
            <select
              value={gender}
              onChange={(v) => setGender(v.target.value)}
              className="w-full border rounded border-[#aeb7c0] px-2 py-1 outline-none"
            >
              <option value={"Male"}>Male</option>
              <option value={"Female"}>Female</option>
            </select>
            {genderError && (
              <p className="text-[9px] italic text-danger leading-none mt-1">
                {genderError}
              </p>
            )}
          </div>
          <div className="mt-2">Email *</div>
          <div className="">
            <input
              value={email}
              onChange={(v) => setEmail(v.target.value)}
              className="w-full border rounded border-[#aeb7c0] px-2 py-1  outline-none"
              type="email"
              placeholder="Your Email"
            />
            {emailError && (
              <p className="text-[9px] italic text-danger leading-none mt-1">
                {emailError}
              </p>
            )}
          </div>
          <div className="mt-2">Phone *</div>
          <div className="">
            <input
              value={phone}
              onChange={(v) => setPhone(v.target.value)}
              className="w-full border rounded border-[#aeb7c0] px-2 py-1  outline-none"
              type="phone"
              placeholder="Your Phone"
            />
            {phoneError && (
              <p className="text-[9px] italic text-danger leading-none mt-1">
                {phoneError}
              </p>
            )}
          </div>
          <div className="mt-2">Address *</div>
          <div className="">
            <input
              value={address}
              onChange={(v) => setAddress(v.target.value)}
              className="w-full border rounded border-[#aeb7c0] px-2 py-1 outline-none"
              type="text"
              placeholder="Your Address"
            />
            {addressError && (
              <p className="text-[9px] italic text-danger leading-none mt-1">
                {addressError}
              </p>
            )}
          </div>
          <div className="mt-2">Booking Date *</div>
          <div className="">
            <input
              value={date}
              onChange={(v) => setDate(v.target.value)}
              className="w-full border rounded border-[#aeb7c0] px-2 py-1 outline-none"
              type={"datetime-local"}
              placeholder="Your Booking Date"
            />
            {dateError && (
              <p className="text-[9px] italic text-danger leading-none mt-1">
                {dateError}
              </p>
            )}
          </div>
          <div className="mt-2">Type *</div>
          <div className="my-1">
            {loadingTypes ? (
              <Loader />
            ) : errorTypes ? (
              <Alert type={"error"}>{errorTypes}</Alert>
            ) : (
              types?.map((item, index) => (
                <div className="flex flex-row items-center cursor-pointer my-3">
                  <input
                    type={"radio"}
                    id={"select-" + index}
                    value={bookingSelect}
                    checked={bookingSelect === item.id}
                    onChange={(v) => setBookingSelect(item.id)}
                    className="mx-2"
                  />
                  <label for={"select-" + index} className="mx-1 flex-1">
                    {item.title_type}
                  </label>
                </div>
              ))
            )}
            {/* {TYPESELECT?.map((item, index) => (
              <div className="flex flex-row items-center cursor-pointer my-3">
                <input
                  type={"radio"}
                  id={"select-" + index}
                  value={bookingSelect}
                  checked={bookingSelect === item}
                  onChange={(v) => setBookingSelect(item)}
                  className="mx-2"
                />
                <label for={"select-" + index} className="mx-1 flex-1">
                  {item}
                </label>
              </div>
            ))} */}
            {bookingSelectError && (
              <p className="md:text-[9px] text-[6px] px-5 italic text-danger leading-none mt-1">
                {bookingSelectError}
              </p>
            )}
          </div>
          <div className="mt-2">Reason for booking </div>
          <div className="">
            <textarea
              value={bookingRaison}
              onChange={(v) => setBookingRaison(v.target.value)}
              className="w-full border rounded border-[#aeb7c0] px-2 py-1 outline-none"
              placeholder="Your Reason for booking "
              rows={6}
            />
          </div>
          <div className="w-full">
            <button
              disabled={loadingOrderAdd}
              onClick={async () => {
                var check = true;
                setFirstNameError("");
                setLastNameError("");
                setGenderError("");
                setEmailError("");
                setPhoneError("");
                setAddressError("");
                setDateError("");
                setBookingSelectError("");

                if (firstName === "") {
                  setFirstNameError("This field is required.");
                  check = false;
                }

                if (gender === "") {
                  setGenderError("This field is required.");
                  check = false;
                }

                if (email === "") {
                  setEmailError("This field is required.");
                  check = false;
                }
                if (phone === "") {
                  setPhoneError("This field is required.");
                  check = false;
                }
                if (address === "") {
                  setAddressError("This field is required.");
                  check = false;
                }

                if (date === "") {
                  setDateError("This field is required.");
                  check = false;
                }

                if (bookingSelect === "") {
                  setBookingSelectError("This field is required.");
                  check = false;
                }

                if (check) {
                  await dispatch(
                    addNewOrder({
                      first_name: firstName,
                      last_name: lastName ?? "",
                      gender: gender,
                      email: email,
                      phone: phone,
                      address: address,
                      booking_date: date,
                      booking_type: bookingSelect,
                      booking_raison: bookingRaison,
                    })
                  ).then(() => {});
                } else {
                  toast.error("Some fields are required please check");
                }
              }}
              className="mt-2 text-white font-bold w-max mx-auto px-10 py-2 rounded bg-primary"
            >
              {loadingOrderAdd ? "Wait .." : "Booking"}
            </button>
          </div>
          <div className="w-full my-10">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              navigation
              autoplay={{
                delay: 1000,
              }}
              pagination={{ clickable: true }}
              // scrollbar={{ draggable: true }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
            >
              {reviews?.map((review, index) => (
                <SwiperSlide>
                  <div className="  flex items-center bg-cover bg-no-repeat">
                    <div className="w-full h-full ">
                      <div className="md:w-1/2 w-2/3 mx-auto flex flex-col h-full justify-center items-center ">
                        <img src="https://hijamaexpress.net/assets/hijama/man.png" />
                        <div className="my-2">
                          <div className="w-max flex flex-row bg-primary text-white px-4 py-1 rounded-full items-center">
                            <div className="mx-1">5</div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-5 mx-1"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="my-2">{review.content ?? ""}</div>
                        <div className="my-2 font-bold text-2xl">
                          {review.client_name ?? "----"}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="font-bold text-center text-2xl">
            Your Opinion Matters
          </div>
          <div className="mx-1">
            <div className="mt-2">Your Name *</div>
            <div className="">
              <input
                className="w-full border rounded border-[#aeb7c0] px-2 py-1 outline-none"
                type="text"
                value={reviewName}
                onChange={(v) => setReviewName(v.target.value)}
                placeholder="Your Name"
              />
              {reviewNameError && (
                <p className="text-[9px] italic text-danger leading-none mt-1">
                  {reviewNameError}
                </p>
              )}
            </div>
            <div className="mt-2">Your Reviews *</div>
            <div className="">
              <textarea
                value={reviewContent}
                onChange={(v) => setReviewContent(v.target.value)}
                className="w-full border rounded border-[#aeb7c0] px-2 py-1 outline-none"
                placeholder="Your Reviews "
                rows={6}
              />
              {reviewContentError && (
                <p className="text-[9px] italic text-danger leading-none mt-1">
                  {reviewContentError}
                </p>
              )}
            </div>
            <div className="w-full">
              <button
                disabled={loadingReviewAdd}
                onClick={async () => {
                  var check = true;
                  setReviewContentError("");
                  setReviewNameError("");

                  if (reviewName === "") {
                    setReviewNameError("This field is required.");
                    check = false;
                  }
                  if (reviewContent === "") {
                    setReviewContentError("This field is required.");
                    check = false;
                  }

                  if (check) {
                    await dispatch(
                      addNewReview({
                        client_name: reviewName,
                        content: reviewContent,
                      })
                    ).then(() => {});
                  } else {
                    toast.error("Some fields are required please check");
                  }
                }}
                className="mt-2 text-white font-bold w-max mx-auto px-10 py-2 rounded bg-primary"
              >
                {loadingReviewAdd ? "wait ..." : "Send"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterWeb />
    </div>
  );
}

export default BookingScreen;
