import axios from "../../axios";
import {
  TYPE_LIST_REQUEST,
  TYPE_LIST_SUCCESS,
  TYPE_LIST_FAIL,
  //
  TYPE_ADD_REQUEST,
  TYPE_ADD_SUCCESS,
  TYPE_ADD_FAIL,
  //
  TYPE_DETAIL_REQUEST,
  TYPE_DETAIL_SUCCESS,
  TYPE_DETAIL_FAIL,
  //
  TYPE_UPDATE_REQUEST,
  TYPE_UPDATE_SUCCESS,
  TYPE_UPDATE_FAIL,
  //
  TYPE_DELETE_REQUEST,
  TYPE_DELETE_SUCCESS,
  TYPE_DELETE_FAIL,
} from "../constants/typeConstants";

export const getTypeList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TYPE_LIST_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`/types/`, config);

    dispatch({
      type: TYPE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;

    dispatch({
      type: TYPE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
