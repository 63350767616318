import "./App.css";
import "./axios.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import HomeScreen from "./screens/HomeScreen.js";
import AboutScreen from "./screens/AboutScreen.js";
import ServiceScreen from "./screens/ServiceScreen.js";
import ContactScreen from "./screens/ContactScreen.js";
import BookingScreen from "./screens/BookingScreen.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/about-us",
    element: <AboutScreen />,
  },
  {
    path: "/our-services",
    element: <ServiceScreen />,
  },
  {
    path: "/contact-us",
    element: <ContactScreen />,
  },
  {
    path: "/booking-now",
    element: <BookingScreen />,
  },

  //
  // {
  //   path: "/profile",
  //   element: <ProfileScreen />,
  // },
  // {
  //   path: "/logout",
  //   element: <LogoutScreen />,
  // },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
