import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function HeaderWeb() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (userInfo) {
    }
  }, [userInfo]);
  return (
    <div>
      {/* menu */}
      <div className="bg-white shadow-1 fixed w-full z-999 ">
        <div className="container mx-auto py-1 ">
          <div className="flex flex-row justify-between items-center">
            {/* logo */}
            <a href="/">
              <img
                className="size-20"
                src="https://hijamaexpress.net/assets/hijama/hijamalogo.jpeg"
              />
            </a>
            <button
              onClick={() => {
                setIsOpen(true);
              }}
              className="md:hidden  md:mx-0 mx-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6 font-bold"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            </button>
            {/*  */}
            <div className="md:flex flex-row items-center mx-1 hidden">
              <div className="relative mx-3 cursor-pointer text-black hover:text-danger  group">
                <a href="/" className="relative">
                  Home
                </a>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
              </div>
              <div className="relative mx-3 cursor-pointer text-black hover:text-danger  group">
                <a href="/about-us" className="relative">
                  About Us
                </a>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
              </div>
              <div className="relative mx-3 cursor-pointer text-black hover:text-danger  group">
                <a href="/our-services" className="relative">
                  Services
                </a>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
              </div>
              <div className="relative mx-3 cursor-pointer text-black hover:text-danger  group">
                <a href="/contact-us" className="relative">
                  Contact Us
                </a>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
              </div>
              <div className="relative mx-3 cursor-pointer text-black hover:text-danger  group">
                <a href="/booking-now" className="relative">
                  Book Now
                </a>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
              </div>
            </div>
            {/* mobile */}
            <div
              className={`bg-white overflow-hidden  fixed z-50 top-0  h-max right-0 duration-500 transition-all ${
                isOpen ? "w-full" : "w-0"
              }`}
            >
              <div className="w-screen flex justify-between px-3 bg-white">
                <svg
                  onClick={() => setIsOpen(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-10 h-10 text-[#140083] m-5 cursor-pointer hover:text-[#FF0000]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
                <a onClick={() => setIsOpen(false)} href="/">
                  <img
                    className="h-20"
                    alt="logo"
                    src="https://hijamaexpress.net/assets/hijama/hijamalogo.jpeg"
                  />
                </a>
              </div>
              <div className="w-full my-8 flex flex-col items-center">
                <div className="flex flex-col  items-center mx-1 ">
                  <div className="relative my-4 cursor-pointer text-black hover:text-danger  group">
                    <a href="/" className="relative">
                      Home
                    </a>
                    <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                  </div>

                  <div className="relative my-4 cursor-pointer text-black hover:text-danger  group">
                    <a href="/about-us" className="relative">
                      About Us
                    </a>
                    <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                  </div>
                  <div className="relative my-4 cursor-pointer text-black hover:text-danger  group">
                    <a href="/our-services" className="relative">
                      Services
                    </a>
                    <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                  </div>
                  <div className="relative my-4 cursor-pointer text-black hover:text-danger  group">
                    <a href="/contact-us" className="relative">
                      Contact Us
                    </a>
                    <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                  </div>
                  <div className="relative my-4 cursor-pointer text-black hover:text-danger  group">
                    <a href="/booking-now" className="relative">
                      Book Now
                    </a>
                    <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderWeb;
