import { toast } from "react-toastify";
import {
  REVIEW_LIST_REQUEST,
  REVIEW_LIST_SUCCESS,
  REVIEW_LIST_FAIL,
  //
  REVIEW_ADD_REQUEST,
  REVIEW_ADD_SUCCESS,
  REVIEW_ADD_FAIL,
  //
  REVIEW_DETAIL_REQUEST,
  REVIEW_DETAIL_SUCCESS,
  REVIEW_DETAIL_FAIL,
  //
  REVIEW_UPDATE_REQUEST,
  REVIEW_UPDATE_SUCCESS,
  REVIEW_UPDATE_FAIL,
  //
  REVIEW_DELETE_REQUEST,
  REVIEW_DELETE_SUCCESS,
  REVIEW_DELETE_FAIL,
} from "../constants/reviewConstants";

export const createNewReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case REVIEW_ADD_REQUEST:
      return { loadingReviewAdd: true };
    case REVIEW_ADD_SUCCESS:
      toast.success("Your review has been sent successfully");
      return {
        loadingReviewAdd: false,
        successReviewAdd: true,
      };
    case REVIEW_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingReviewAdd: false,
        successReviewAdd: false,
        errorReviewAdd: action.payload,
      };
    default:
      return state;
  }
};

export const reviewListReducer = (state = { reviews: [] }, action) => {
  switch (action.type) {
    case REVIEW_LIST_REQUEST:
      return { loadingReviews: true, reviews: [] };
    case REVIEW_LIST_SUCCESS:
      return {
        loadingReviews: false,
        reviews: action.payload.reviews,
      };
    case REVIEW_LIST_FAIL:
      return { loadingReviews: false, errorReviews: action.payload };
    default:
      return state;
  }
};
