import React from "react";

function FooterWeb() {
  return (
    <div className="bg-boxdark-2  w-full py-10 ">
      <div className="container mx-auto">
        <div className="flex md:flex-row flex-col justify-center items-center">
          <div className="md:w-1/3 w-full px-3 py-3">
            <div className="md:text-left text-center">
              <img
                className="size-10 my-5 md:mx-0 mx-auto"
                src="https://hijamaexpress.net/assets/hijama/hijamalogo.jpeg"
              />
              <div className="text-primary font-bold">
                © Hijama Express Cupping
              </div>
              <div className="text-gray">2024 All Rights Reserved</div>
            </div>
          </div>
          <div className="md:w-1/3 w-full">
            <div className="md:text-left text-center">
              <div className="flex flex-row items-center text-white my-4 md:justify-start justify-center">
                <img
                  className="size-7 mx-3"
                  src="https://img.icons8.com/flat-round/64/phone.png"
                  alt="phone"
                />
                <div> +0012674983518</div>
              </div>
              <div className="flex flex-row items-center text-white my-4  md:justify-start justify-center">
                <img
                  className="size-7 mx-3"
                  src="https://img.icons8.com/fluency/48/facebook-new.png"
                  alt="phone"
                />
                <div> Express hijama cupping</div>
              </div>
            </div>
          </div>
          <div className="md:w-1/3 w-full text-gray text-sm">
            <div className="md:text-left text-center">
              <b>Hijama</b> is a method of treatment where blood is drawn from
              small superficial skin incisions, through suction from the skin,
              and with it, releases toxins from within the body, reduces muscle
              inflammation, and kickstart the body's immune system
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterWeb;
