import React, { useEffect } from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";
import { getServiceList } from "../redux/actions/serviceActions";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { useDispatch, useSelector } from "react-redux";
import { baseURLFile } from "../constants";

function HomeScreen() {
  const dispatch = useDispatch();

  const listServices = useSelector((state) => state.serviceList);
  const { services, loadingServices, errorServices } = listServices;

  useEffect(() => {
    dispatch(getServiceList());
  }, [dispatch]);

  return (
    <div className="w-full min-h-screen bg-white text-black ">
      <HeaderWeb />
      <div
        className="py-30  object-cover bg-cover bg-no-repeat bg-opacity-45 "
        style={{
          backgroundImage:
            "url('https://hijamaexpress.net/assets/hijama/hijamanew.jpg')",
        }}
      >
        <div
          style={{ textShadow: "0 3px 3px rgba(0, 0, 0, 0.4)" }}
          className="md:text-3xl text-2xl  font-bold text-center my-10 "
        >
          Hijama Express Cupping
        </div>
        <div
          style={{ textShadow: "#FC0 1px 0 10px" }}
          className="text-center my-5 text-white"
        >
          Same Day Appointments No Problem.
        </div>
        <div className="w-full text-center my-5">
          <a
            href="/booking-now"
            className="text-white bg-primary rounded px-10 py-3 font-bold w-max mx-auto"
          >
            Booking Now
          </a>
        </div>
        <div className="text-2xl text-center font-bold  my-5">
          الحجامة علاج وشفاء
        </div>
      </div>
      <div className="flex flex-col items-center  py-10 bg-[#ffc0cb] ">
        <video
          controls
          src="https://hijamaexpress.net/assets/hijama/hijamavedio.mp4"
        />
      </div>
      <div className="container mx-auto py-10 px-2">
        <div className="text-2xl font-bold text-center">OUR SERVICES</div>
        <div className="my-5 grid  md:grid-cols-3 grid-cols-1 gap-3">
          {loadingServices ? (
            <Loader />
          ) : errorServices ? (
            <Alert type={"error"}>{errorServices}</Alert>
          ) : services ? (
            services?.map((item, index) => (
              <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
                <div className="relative group ">
                  <img
                    className="h-60 w-full object-cover"
                    src={baseURLFile + item.image_service}
                    alt={item.title_service}
                  />
                  <div className=" absolute top-3 right-3 bg-black shadow-1 text-white text-xs px-3 py-1 rounded-full">
                    {parseFloat(item.price_service).toFixed(2)}$
                  </div>
                </div>
                <div className="my-3 text-xl font-bold text-center">
                  {item.title_service}
                </div>
                <div className="text-xs text-justify">
                  {item.description_service}
                </div>
              </div>
            ))
          ) : null}
        </div>
      </div>
      <div className="container mx-auto py-10 px-2">
        <div className="text-2xl font-bold text-center">ABOUT US</div>
        <div className="flex md:flex-row flex-col items-center">
          <div className="md:w-1/3 w-full ">
            <img
              className="w-full"
              src="https://hijamaexpress.net/assets/hijama/hijamalogo.jpeg"
            />
          </div>
          <div className="md:w-2/3 w-full ">
            <div className="my-1">
              <b>Hijama</b> is a method of treatment where blood is drawn from
              small superficial skin incisions, through suction from the skin,
              and with it, releases toxins from within the body, reduces muscle
              inflammation, and kickstart the body's immune system.
            </div>
            <div className="my-1">
              <b>Hijama</b> cleanses & detoxifies the body, improves blood
              circulation, aids body pains, and refreshes both the mind and
              body! It can provide relief to many different conditions and acts
              as a preventative measure against many kinds of diseases.
            </div>
          </div>
        </div>
      </div>
      <FooterWeb />
    </div>
  );
}

export default HomeScreen;
