import React from "react";
import FooterWeb from "../layouts/FooterWeb";
import HeaderWeb from "../layouts/HeaderWeb";

function ContactScreen() {
  return (
    <div className="w-full min-h-screen bg-white text-black">
      <HeaderWeb />
      <div className="py-15  "></div>
      <div className="container mx-auto py-10  px-2">
        <div className="text-2xl font-bold text-center">CONTACT US</div>
        <div className="my-5 text-center font-bold">
          If you have any questions about our services, you can contact us by
          filling in the following information
        </div>
        <div className="md:w-1/2 mx-auto w-full my-10">
          <div className="mt-2">Name *</div>
          <div className="">
            <input
              className="w-full border border-[#aeb7c0] rounded px-2 py-1 outline-none"
              type="text"
              placeholder="Your Name"
            />
          </div>
          <div className="mt-2">Email *</div>
          <div className="">
            <input
              className="w-full border border-[#aeb7c0] rounded px-2 py-1  outline-none"
              type="email"
              placeholder="Your Email"
            />
          </div>
          <div className="mt-2">Phone *</div>
          <div className="">
            <input
              className="w-full border border-[#aeb7c0] rounded px-2 py-1  outline-none"
              type="phone"
              placeholder="Your Phone"
            />
          </div>
          <div className="mt-2">Your inquiry or message *</div>
          <div className="">
            <textarea
              className="w-full border  border-[#aeb7c0] rounded px-2 py-1 outline-none"
              placeholder="Your inquiry or message "
              rows={6}
            />
          </div>
          <div className="w-full">
            <button className="mt-2 text-white font-bold w-max mx-auto px-10 py-2 rounded bg-primary">
              Send
            </button>
          </div>
        </div>
      </div>
      <FooterWeb />
    </div>
  );
}

export default ContactScreen;
