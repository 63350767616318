export const REVIEW_LIST_REQUEST = "REVIEW_LIST_REQUEST";
export const REVIEW_LIST_SUCCESS = "REVIEW_LIST_SUCCESS";
export const REVIEW_LIST_FAIL = "REVIEW_LIST_FAIL";

export const REVIEW_ADD_REQUEST = "REVIEW_ADD_REQUEST";
export const REVIEW_ADD_SUCCESS = "REVIEW_ADD_SUCCESS";
export const REVIEW_ADD_FAIL = "REVIEW_ADD_FAIL";

export const REVIEW_DETAIL_REQUEST = "REVIEW_DETAIL_REQUEST";
export const REVIEW_DETAIL_SUCCESS = "REVIEW_DETAIL_SUCCESS";
export const REVIEW_DETAIL_FAIL = "REVIEW_DETAIL_FAIL";

export const REVIEW_UPDATE_REQUEST = "REVIEW_UPDATE_REQUEST";
export const REVIEW_UPDATE_SUCCESS = "REVIEW_UPDATE_SUCCESS";
export const REVIEW_UPDATE_FAIL = "REVIEW_UPDATE_FAIL";

export const REVIEW_DELETE_REQUEST = "REVIEW_DELETE_REQUEST";
export const REVIEW_DELETE_SUCCESS = "REVIEW_DELETE_SUCCESS";
export const REVIEW_DELETE_FAIL = "REVIEW_DELETE_FAIL";
