import React, { useEffect } from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";
import { useDispatch, useSelector } from "react-redux";
import { getServiceList } from "../redux/actions/serviceActions";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { baseURLFile } from "../constants";

function ServiceScreen() {
  const dispatch = useDispatch();

  const listServices = useSelector((state) => state.serviceList);
  const { services, loadingServices, errorServices } = listServices;

  useEffect(() => {
    dispatch(getServiceList());
  }, [dispatch]);
  return (
    <div className="w-full min-h-screen bg-white text-black">
      <HeaderWeb />
      <div className="py-15  "></div>
      <div className="container mx-auto py-10">
        <div className="text-2xl font-bold text-center">OUR SERVICES</div>
        <div className="my-5 grid  md:grid-cols-3 grid-cols-1 gap-3">
          {loadingServices ? (
            <Loader />
          ) : errorServices ? (
            <Alert type={"error"}>{errorServices}</Alert>
          ) : services ? (
            services?.map((item, index) => (
              <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
                <div className="relative group ">
                  <img
                    className="h-60 w-full object-cover"
                    src={baseURLFile + item.image_service}
                    alt={item.title_service}
                  />
                  <div className=" absolute top-3 right-3 bg-black shadow-1 text-white text-xs px-3 py-1 rounded-full">
                    {parseFloat(item.price_service).toFixed(2)}$
                  </div>
                </div>
                <div className="my-3 text-xl font-bold text-center">
                  {item.title_service}
                </div>
                <div className="text-xs text-justify">
                  {item.description_service}
                </div>
              </div>
            ))
          ) : null}
        </div>
      </div>
      <FooterWeb />
    </div>
  );
}

export default ServiceScreen;
